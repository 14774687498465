import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { type FeatureFlag, type ToggleFeatureFlagProps, type AddFlagBody } from '@/types/FeatureFlag';
import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';

import DebounceCache from '@/utils/cache/DebounceCache';
import { type AxiosResponse } from 'axios';

const cache = new DebounceCache<string, AxiosResponse<{ data: boolean }>>();

export const fetchFeatureFlags = createAsyncThunk('fetchFeatureFlags', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await authAxios.get<{ data: any }>('/feature-flags');
    if (data?.data && data.data.length > 0) {
      const result: FeatureFlag[] = data.data as FeatureFlag[];
      return result;
    }
    return [];
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const addFeatureFlag = createAsyncThunk(
  'featureFlags.addFlag',
  async ({ body }: { body: AddFlagBody }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: AddFlagBody }>('/feature-flags', body);
      successToast();
      return data.data;
    } catch (err: any) {
      const { response } = err;
      const errorResponse = response?.data?.errors.map(
        (e: { code: string }) => `toast-messages.feature-flags-error-${e.code}`,
      ) as string;
      errorToast(errorResponse);
      return rejectWithValue(err);
    }
  },
);

export const updateFeatureFlag = createAction<FeatureFlag | ToggleFeatureFlagProps>('updateFeatureFlag');

export const getFeatureFlag = createAsyncThunk<{ flagId: string; value: boolean }, { flagId: string }, { rejectValue: any }>(
  'featureFlags/getFeatureFlag',
  async ({ flagId }) => {
    try {
      const response = await cache.getResult(
        flagId,
        async () => await authAxios.get<{ data: boolean }>('/feature-flags/validation', { params: { flagId } }),
      );
      return { flagId, value: response.data.data };
    } catch (err: any) {
      return { flagId, value: false };
    }
  },
);
