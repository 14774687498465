import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchFeatureFlags, addFeatureFlag, updateFeatureFlag, getFeatureFlag } from './actions';
import { type FeatureFlag, type Feature } from '@/types/FeatureFlag';

export interface FlagValue {
  value?: boolean;
}

export type Flags = {
  [K in Feature]?: FlagValue;
};

export interface FeatureFlagsSliceState {
  featureFlags: FeatureFlag[];
  feature: Flags;
  loading: boolean;
  isLoadingAddFlag: boolean;
  successfulFlagAddition: boolean;
}

const initialState: FeatureFlagsSliceState = {
  featureFlags: [],
  feature: {},
  loading: false,
  isLoadingAddFlag: false,
  successfulFlagAddition: false,
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    resetFeatureFlag(state) {
      state.successfulFlagAddition = false;
    },
  },
  extraReducers: builder => {
    // Get feature flags
    builder.addCase(fetchFeatureFlags.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchFeatureFlags.fulfilled, (state, action: PayloadAction<FeatureFlag[]>) => {
      state.loading = false;
      state.featureFlags = action.payload;
    });
    builder.addCase(fetchFeatureFlags.rejected, state => {
      state.loading = false;
      state.featureFlags = [];
    });
    // Add feature flags
    builder.addCase(addFeatureFlag.pending, state => {
      state.isLoadingAddFlag = true;
    });
    builder.addCase(addFeatureFlag.fulfilled, (state, action) => {
      state.isLoadingAddFlag = false;
      state.successfulFlagAddition = true;
      state.featureFlags.unshift(action.payload);
    });
    builder.addCase(addFeatureFlag.rejected, state => {
      state.isLoadingAddFlag = false;
    });
    // Update feature flag
    builder.addCase(updateFeatureFlag, (state, action) => {
      state.featureFlags = state.featureFlags.map(featureFlag => {
        return featureFlag.id !== action.payload.id ? featureFlag : { ...featureFlag, ...action.payload };
      });
    });
    // Hide feature
    builder.addCase(getFeatureFlag.pending, (state, action) => {
      const { flagId } = action.meta.arg;
      state.feature[flagId as Feature] = {};
    });
    builder.addCase(getFeatureFlag.fulfilled, (state, action) => {
      const { flagId } = action.meta.arg;
      state.feature[flagId as Feature] = { value: action.payload.value };
    });
    builder.addCase(getFeatureFlag.rejected, (state, action) => {
      state.feature[action.payload.flagId as Feature] = { value: false };
    });
  },
});

export const { reset, resetFeatureFlag } = featureFlagsSlice.actions;
